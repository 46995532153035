import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import VideoSlider from "../components/VideoSlider";

const Home = () => {
  return (
    <>
      <div>
        <div id="skip-link">
          <a
            href="#main-content"
            className="element-invisible element-focusable"
          >
            Skip to main content
          </a>
        </div>
        <div className="body-innerwrapper">
          <Header />
          {/* slider start */}
          {/* <Slider /> */}
          <VideoSlider />
          {/* slider end */}

          <div
            className="main-container container"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            <header role="banner" id="page-header"></header>{" "}
            {/* /#page-header */}
            <div className="row">
              <section className="col-sm-12">
                <a id="main-content" />
                <h1 className="page-header">
                  Welcome to Sophia college of Nursing
                </h1>
                <div className="region region-content">
                  <article
                    id="node-29"
                    className="node node-page clearfix"
                    about="/welcome-sushrutha-institute-nursing"
                    typeof="foaf:Document"
                  >
                    <header>
                      <span
                        property="dc:title"
                        content="
Welcome to Sophia college of Nursing
"
                        className="rdf-meta element-hidden"
                      />{" "}
                    </header>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <div className="banner-overlap">
                            <div className="col-sm-3 col">
                              <Link to="/bscnursing">
                                <h3>Courses</h3>
                                <p
                                  classname="small"
                                  style={{ fontSize: "75% !important" }}
                                >
                                  Know more about the courses offered at Sophia
                                  <img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" />
                                </p>
                              </Link>
                            </div>
                            <div className="col-sm-3 col">
                              <Link to="/admission">
                                <h3>Admission</h3>
                                <p
                                  className="small"
                                  style={{ fontSize: "75% !important" }}
                                >
                                  Get informtaion about the admission procedures
                                  <img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" />
                                </p>
                              </Link>
                            </div>
                            <div className="col-sm-3 col">
                              <Link to="/facilities">
                                <h3>Facilities</h3>
                                <p
                                  className="small"
                                  style={{ fontSize: "75% !important" }}
                                >
                                  Explore the facilities provided at the campus
                                  <img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" />
                                </p>
                              </Link>
                            </div>
                            <div className="col-sm-3 col">
                              <Link to="/campus">
                                <h3>Campus</h3>
                                <p
                                  className="small"
                                  style={{ fontSize: "75% !important" }}
                                >
                                  Find out more about our campus and activities
                                  <img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" />
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </div>
          <div className="region region-content-bottom">
            <section id="block-block-21" className="block block-block clearfix">
              <div className="content-block">
                {/* row */}
                <div
                  className="bottom-space"
                  style={{ width: "100%", minHeight: "500px" }}
                >
                  <div className="col-sm-6 image image-right">
                    <img
                      className="img-responsive enlarge"
                      src="./images/welcome-to-sophia.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 text text-on-left">
                    <h2>Welcome to Sophia college of Nursing</h2>
                    <p>
                      Sophia is the name that connect with knowledge, power, and
                      bravery
                      <br />
                      The Institute offers courses from Primary Education to
                      Post Graduation in Nursing.
                    </p>
                    <Link className="btn" to="/about">
                      Know more
                    </Link>
                  </div>
                </div>
                {/* row */}
                <div className="" style={{ width: "100%" }}>
                  <div className="col-sm-6 image">
                    <img
                      className="img-responsive"
                      src="/images/why-to -choose-career.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 text text-on-right">
                    <h2>Why to choose a career in Nursing?</h2>
                    <p>
                      Nursing is a profession dedicated to caring for others and
                      only few careers are as personally rewarding as nursing.
                      <br />
                      Choosing to become a nurse can provide you with a job that
                      provides personal fulfillment, job security and
                      flexibility.
                    </p>
                    <Link className="btn" to="/career">
                      Know more
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section id="block-block-17" className="block block-block clearfix">
              <h2 className="block-title">Courses we offer</h2>
              <div className="row-1 prod-row">
                {/* <div className="col-sm-4 col">
                                <img className="img-responsive" src="sites/all/themes/sushrutha/images/courses-thumb-msc.jpg" />
                                <div className="text-on-bottom">
                                    <h3><a href="msc-nursing.html">M.Sc. Nursing</a></h3>
                                </div>
                            </div> */}
                {/*--
<div class="col-sm-3 col">
<img class="img-responsive" src="/sites/all/themes/sushrutha/images/courses-thumb-post-basic.jpg">
<div class="text-on-bottom">
<h3><a href="/post-basic-bsc-nursing">Post Basic B.Sc. Nursing</a></h3>
</div>
</div>
-*/}
                <div className="col-sm-4 col">
                  <img
                    className="img-responsive"
                    src="./images/bsc-nursing.jpeg"
                  />
                  <div className="text-on-bottom">
                    <h3>
                      <Link to="/bscnursing">B.Sc. Nursing</Link>
                    </h3>
                  </div>
                </div>
                {/* <div className="col-sm-4 col">
                                <img className="img-responsive" src="sites/all/themes/sushrutha/images/courses-thumb-diploma.jpg" />
                                <div className="text-on-bottom">
                                    <h3><a href="diploma-general-nursing-midwifery-gnm.html">Diploma in General Nursing &amp; Midwifery (G.N.M.)</a></h3>
                                </div>
                            </div> */}
              </div>
            </section>
          </div>
          <div className="region region-content-bottom-bottom">
            <section id="block-block-20" className="block block-block clearfix">
              <div className="text-block" id="text-block-1">
                <div className="row">
                  <p className="text-center text-uppercase">
                    <small>Our Specialities</small>
                  </p>
                  <p className="lead text-center">
                    We believe in Discipline, Quality and Professionalism.{" "}
                    <br />
                    Our vision is to focus on the students, giving them
                    education and skills so they can perform better in both
                    profession and in life.
                  </p>
                  <div className="blocks-within-textblock">
                    <div className="col-sm-4">
                      <img
                        className="center-block"
                        src="sites/all/themes/sushrutha/images/icon-blue-content-bottom.png"
                      />
                      <p className="text-center">
                        Discipline and routine are the keys to success,
                        especially in education. A disciplined person can stay
                        focused, manage their time effectively, and consistently
                        work toward their goals. This mindset helps them
                        overcome obstacles and achieve long-term success in
                        life.
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <img
                        className="center-block"
                        src="sites/all/themes/sushrutha/images/icon-blue-2-content-bottom.png"
                      />
                      <p className="text-center">
                        Quality education is the foundation of success. By
                        prioritizing excellence in our teaching, we ensure that
                        our students are well-prepared and stand out ahead of
                        their competitors.
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <img
                        className="center-block"
                        src="sites/all/themes/sushrutha/images/icon-blue-3-content-bottom.png"
                      />
                      <p className="text-center">
                        In today’s era of global opportunities, our education
                        system is designed to equip students with the skills and
                        knowledge needed to excel on the world stage. We are
                        preparing professionals ready to seize global
                        opportunities and thrive in a competitive environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="block-block-4" className="block block-block clearfix">
              <p className="lead text-center">
                "Nursing is an art: and if it is to be made an art, it requires
                an exclusive devotion as hard a preparation, as any painter's or
                sculptor's work."
              </p>
              <h3 className="text-center">
                <em> - Florence Nightingale</em>
                <br />
                <span className="small">Founder of Modern Nursing</span>
              </h3>
            </section>
            <section id="block-block-9" className="block block-block clearfix">
              <div className="center">
                <p className="lead">
                  Are you ready to earn your nursing degree?
                  <Link className="btn" to="contact">
                    Contact Us to know more
                  </Link>
                </p>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Home;
