import React, { useEffect, useState } from "react";
import axios from "axios";

const Footer = () => {
    const [facebookUrl, setFacebookUrl] = useState("");
    const [googleUrl, setGoogleUrl] = useState("");
    const [instgramUrl, setInstgramUrl] = useState("");
    const [whatsappUrl, setWhatsappUrl] = useState("");
    const [twitterUrl, setTwitterUrl] = useState("");

    const [youtubeUrl, setYoutubeUrl] = useState("");
    useEffect(() => {
        const fetchWebsiteInfo = async () => {
            try {
                const response = await axios.get('https://api.sophiacollegeofnursing.com/getWebsiteInfo');
                console.log('Response:', response.data);
                setFacebookUrl(response.data.data.facebook_url);
                setGoogleUrl(response.data.data.google_url);
                setInstgramUrl(response.data.data.instgram_url);
                setTwitterUrl(response.data.data.twitter_url);
                setWhatsappUrl(response.data.data.whatsapp_url);
                setYoutubeUrl(response.data.data.youtube_url);

            } catch (error) {
                console.error('Error fetching website info:', error);
            }
        };
        fetchWebsiteInfo();
    }, []);

    return (
        <>
            <footer className="footer container" style={{ width: '100%', maxWidth: '100%' }}>
                <div className="region region-footer">
                    <section id="block-block-15" className="block block-block clearfix">
                        <a href="/"><img className="img-responsive" src="./images/sen-logo.png" style={{ backgroundColor: '#fff', borderRadius: '20px', width: '150px' }} /></a>


                    </section>
                    <section id="block-menu-menu-footer-menu" className="block block-menu clearfix">
                        <h2 className="block-title">Quick Links</h2>
                        <ul>
                            <li className="leaf"><a href="/" title>Home</a></li>
                            <li className="leaf">
                                <a href="/about" title>About</a>
                            </li>
                        </ul>
                    </section>
                    <section id="block-block-14" className="block block-block clearfix">
                        <h2 className="block-title">Contact</h2>
                        <div className="address">
                            <img src="/sites/all/themes/sushrutha/images/icon-marker.png" />
                            {/* <img src="/sites/all/themes/sushrutha/images/icon-marker.png" /> */}
                            <p>Banashankari 2nd <br />
                                stage Amarjoythinagar<br />
                                Hemavathi Extension<br />
                                Tumkur -572105 </p>
                        </div>
                        <div className="phone">
                            <img src="/sites/all/themes/sushrutha/images/icon-phone.png" />
                            <p>+91 9743216678
                                <br />
                                +91 7795840617</p>
                        </div>
                        <div className="email-id">
                            <img src="/sites/all/themes/sushrutha/images/icon-email.png" />
                            <p>sophiacollegeofnursing@gmail.com</p>
                        </div>
                    </section>
                    <section id="block-block-1" className="block block-block clearfix">
                        <p>Copyright&nbsp;© {new Date().getFullYear()} Sophia  Institute of Nursing. All Rights Reserved.</p>
                        <ul>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-conditions">Terms &amp; Conditions</a></li>
                        </ul>
                    </section>
                    {/* <section id="block-menu-menu-login-logout" className="block block-menu clearfix">
                    <ul className="menu nav"><li className="first last leaf"><a href="/user/login" title>Login</a></li>
                    </ul>
                </section> */}
                </div>
            </footer>

            {/* The social media icon bar */}
            <div className="icon-bar">
                <a href={facebookUrl} className="facebook"><i className="fa fa-facebook" /></a>
                <a href={twitterUrl} className="twitter"><i className="fa fa-twitter" /></a>
                {/* <a href={googleUrl} className="google"><i className="fa fa-google" /></a> */}
                <a href={instgramUrl} className="instagram"><i className="fa fa-instagram" /></a>
                <a href={youtubeUrl} className="youtube"><i className="fa fa-youtube" /></a>
                <a href={whatsappUrl} className="whatsapp"><i className="fa fa-whatsapp" /></a>

            </div>
        </>
    )
}

export default Footer;