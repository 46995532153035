import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Footer from "../components/Footer";
import Header from "../components/Header";

const Contact = () => {


  // Define state variables for each input field
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');


  // Handle change for each input field
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const validatePhoneNumber = (phoneNumber) => {
    // Basic phone number validation (supports formats like 1234567890, (123) 456-7890, or 123-456-7890)
    const phoneRegex = /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s\-]?)?[\d\s\-]{7,13}$/;
    return phoneRegex.test(phoneNumber);
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission (page reload)
    // You can handle the form data here (e.g., send it to an API)
    // console.log('Form Submitted:', { name, email, phone, course });
    // You can also reset the form fields if needed
    if (!name || !email || !phone || !message) {
      alert('All fields are required.');
      return;
    }
    if (!validatePhoneNumber(phone)) {
      alert('Please enter a valid phone number');
      return;
    }

    // const formData = {
    //     name,
    //     email,
    //     phone,
    //     course,
    //   };

    const formData = {
      "name": name,
      "email": email,
      "mobile_no": phone,
      "subject": subject,
      "message": message
    }


    try {
      // Make POST request using Axios
      const response = await axios.post('https://api.sophiacollegeofnursing.com/addContact', formData);

      // Handle success
      // setSuccess('Form submitted successfully!');
      console.log('Response:', response.data);
      alert('submitted successfully!');
      setName('');
      setEmail('');
      setPhone('');
      // setCourse('');
    } catch (err) {
      // Handle error
      // setError('There was an error submitting the form. Please try again.');
      alert('There was an error submitting the form. Please try again.');
      console.error('Error:', err);
      setName('');
      setEmail('');
      setPhone('');
      // setCourse('');
    } finally {
      // Set loading to false after request is done
      // setLoading(false);
    }
  };
  return (
    <>

      <div>
        <div id="skip-link">
          <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
        </div>
        <div className="body-innerwrapper">
          <Header />
          <div className="region region-banner">
            <section id="block-block-29" className="block block-block clearfix">
              <div className="iframe-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.3939163767373!2d77.100844!3d13.3257551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb02f61a12f0711%3A0xe8c546e149b0ddd9!2sSophia%20college%20of%20nursing!5e0!3m2!1sen!2sin!4v1735377156066!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen loading="lazy" />
              </div>
            </section>
          </div>

          {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          <div className="main-container container" style={{ width: '100%',maxWidth:'100%', margin: 0 }}>
            <header role="banner" id="page-header">
            </header> {/* /#page-header */}
            <div className="row">
              <section className="col-sm-8">
                <a id="main-content" />
                <h1 className="page-header">Get in Touch</h1>
                <div className="region region-content">
                  <section id="block-system-main" className="block block-system clearfix">
                    <article id="node-38" className="node node-webform clearfix" about="/contact" typeof="sioc:Item foaf:Document">
                      <header>
                        <span property="dc:title" content="Get in Touch" className="rdf-meta element-hidden" />    </header>
                      <form className="webform-client-form webform-client-form-38" onSubmit={handleSubmit} >

                        <div>

                          <div className="form-item webform-component webform-component-textfield webform-component--name webform-container-inline form-group form-inline form-item form-item-submitted-name form-type-textfield form-group">

                            <label className="control-label" htmlFor="edit-submitted-name">Name

                              <span className="form-required" title="This field is required.">*</span></label>
                            <input required="required" className="form-control form-text required" value={name} onChange={handleNameChange} type="text" name="name" size={60} maxLength={128} />

                          </div>


                          <div className="form-item webform-component webform-component-email webform-component--email webform-container-inline form-group form-inline form-item form-item-submitted-email form-type-webform-email form-group">

                            <label className="control-label" >Email <span className="form-required" title="This field is required.">*</span></label>
                            <input required="required" className="email form-control form-text form-email required" type="email" name="email" value={email} onChange={handleEmailChange} size={60} /></div>

                          <div className="form-item webform-component webform-component-textfield webform-component--phone webform-container-inline form-group form-inline form-item form-item-submitted-phone form-type-textfield form-group">

                            <label className="control-label" htmlFor="edit-submitted-phone">Phone <span className="form-required" title="This field is required.">*</span>
                            </label>

                            <input required="required" className="form-control form-text required" type="text" name="phone" size={60} maxLength={128} value={phone} onChange={handlePhoneChange} />
                          </div>

                          <div className="form-item webform-component webform-component-textfield webform-component--phone webform-container-inline form-group form-inline form-item form-item-submitted-phone form-type-textfield form-group">

                            <label className="control-label" htmlFor="edit-submitted-phone">Subject <span className="form-required" title="This field is required.">*</span>
                            </label>

                            <input required="required" className="form-control form-text required" type="text" name="subject" size={60} maxLength={128} value={subject} onChange={handleSubjectChange} />
                          </div>

                          <div className="form-item webform-component webform-component-textarea webform-component--message webform-container-inline form-group form-inline form-item form-item-submitted-message form-type-textarea form-group">

                            <label className="control-label" htmlFor="edit-submitted-message">Message

                              <span className="form-required" title="This field is required.">*</span></label>
                            <div className="form-textarea-wrapper resizable">

                              <textarea required="required" className="form-control form-textarea required" name="message" value={message} onChange={handleMessageChange} cols={60} rows={5} defaultValue={""} />
                            </div>
                          </div>



                          <div className="form-actions">

                            <button className="webform-submit button-primary btn btn-primary form-submit" type="submit" name="op" value="Submit">Submit</button>
                          </div>
                        </div>
                      </form>
                    </article>
                  </section>
                </div>
              </section>
              <aside className="col-sm-4" role="complementary">
                <div className="region region-sidebar-second">
                  <section id="block-block-16" className="block block-block clearfix">
                    <h2 className="block-title">Reach Us</h2>
                    <div className="address">
                      <img src="/sites/all/themes/sushrutha/images/icon-marker.png" />
                      <p>Banashankari 2nd <br/>
stage Amarjoythinagar<br/>
Hemavathi Extension<br/>
Tumkur -572105 </p>
                    </div>
                    <div className="phone">
                      <img src="/sites/all/themes/sushrutha/images/icon-phone.png" />
                      <p>+91 9743216678<br />
                      +91 7795840617</p>
                    </div>
                    <div className="email-id">
                      <img src="/sites/all/themes/sushrutha/images/icon-email.png" />
                      <p>sophiacollegeofnursing@gmail.com</p>
                    </div>
                  </section>
                </div>
              </aside>  {/* /#sidebar-second */}
            </div>
          </div>
          <Footer />
        </div>
      </div>

    </>
  )
}

export default Contact;