import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Campus = () => {
  return (
    <div>
      <div id="skip-link">
        <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
      </div>
      <div className="body-innerwrapper">
        <Header />
        <div className="region region-banner">
          <section id="block-views-inner-banner-block" className="block block-views clearfix">
            <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-b1a05761c4a25cc3472ab24495325771">
              <div className="view-content">
                <div className="skin-default">
                  <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                    <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                      <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                        <div className="views-field views-field-field-slide-image">        <div className="field-content" /></div></div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="main-container container" style={{ width: '100%', maxWidth: '100%', margin: 0 }}>
          <header role="banner" id="page-header">
          </header> {/* /#page-header */}
          <div className="row">
            <section className="col-sm-12">
              <ol className="breadcrumb"><li><span title className="nolink" tabIndex={0}>College</span></li>
                <li className="active">Campus</li>
              </ol>      <a id="main-content" />
              <h1 className="page-header">Campus</h1>
              <div className="region region-content">
                <section id="block-system-main" className="block block-system clearfix">
                  <article id="node-60" className="node node-page clearfix" about="/campus" typeof="foaf:Document">
                    <header>
                      <span property="dc:title" content="Campus" className="rdf-meta element-hidden" />    
                      
                      </header>

                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      
                      <div className="field-items">                      
                      <div className="field-item even" property="content:encoded">
                      {/* <p className="lead text-center">Sophia Institute of Nursing is a constituent of Sophia Group of Institutions established in the year 2003. The Institute is located in the S-VYASA University campus, at Prashanthi Kuteera on the outskirts of Bengaluru city. </p> */}
                      <br />
                      <br />
                      <div className="content-block">
                        <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            <img className="img-responsive enlarge" src="./images/campus.jpeg" />
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2>Our Campus</h2>
                            <p>This internationally famed campus was established by a great visionary Guruji Sri Dr. H.R. Nagendra who relived the thoughts of Swami Vivekananda with combined efforts of Dr. R. Nagaratna &amp; many others.The Nursing Institute is proud of its 100 Acres lush and green campus and have a glorious 17 years of excellence in the ﬁeld of nursing education.</p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image">
                            <img className="img-responsive enlarge" src="./images/hostel.jpg" />
                          </div>
                          <div className="col-sm-6 text text-on-right">
                            <h2>Hostel</h2>
                            <p>The students are provided with Hostel facilities under the direct supervision of warden. Well-furnished hostels for both boys and girls separately. </p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            <img className="img-responsive enlarge" src="/images/curricular.jpeg" />
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2>Curricular &amp; Extra Curricular Activities</h2>
                            <p>The college along with SNA unit celebrates Annual Day, Nurse's Day, World Health Days &amp; Cultural Fest etc every year.</p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image">
                            <img className="img-responsive enlarge" src="./images/sports.jpg" />
                          </div>
                          <div className="col-sm-6 text text-on-right">
                            <h2>Sports</h2>
                            <p>Facilities for Indoor and outdoor games  are available. Annual competitions are held for the students and the winners are rewarded. </p>
                          </div>
                        </div>
                        {/* <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            <img className="img-responsive enlarge" src="./images/sports.jpg" />
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2></h2>
                            <p>Facilities for Indoor and outdoor games  are available. Annual competitions are held for the students and the winners are rewarded. </p>
                          </div>
                        </div> */}
                      </div></div></div></div>  </article>
                </section>
              </div>
            </section>
          </div>
        </div>
        <div className="region region-content-bottom-bottom">
          <section id="block-block-9" className="block block-block clearfix">
            <div className="center">
              <p className="lead">Are you ready to earn your nursing degree?
                <a className="btn" href="/contact">Contact Us to know more</a></p>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>

  )
}
export default Campus;