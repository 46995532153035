// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Bscnursing from './pages/Bscnursing';
import Facilities from './pages/Facilities';
import Campus from './pages/Campus';
import Admission from './pages/Admission';
import Photos from './pages/Photos';
import Career from './pages/Career';
import Contact from './pages/Contact';
import NotFound from "./pages/NotFound";
import PrivacPolicy from './pages/PrivacPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Gallery from './pages/Gallery';


function App() {
  return (
    <Router>
    <Routes>
      {/* <Route path="/" element={<Layout />}> */}
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="bscnursing" element={<Bscnursing />} />
        <Route path="facilities" element={<Facilities />} />
        <Route path="campus" element={<Campus />} />
        <Route path="admission" element={<Admission />} />
        <Route path="photos" element={<Photos />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="career" element={<Career />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="privacy-policy" element={<PrivacPolicy />} />
        <Route path="terms-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
      {/* </Route> */}
    </Routes>
  </Router>

  );
}

export default App;
